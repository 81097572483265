import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSpaRoutingModule } from './app-spa-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { StateModule } from '@state';
import { ServicesModule } from '@services';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { I18Service, TranslocoRootModule } from '@translation';
import { ComponentsModule } from './components/components.module';
import { DesignSystemModule } from '@compeople-shared/design-system-angular';
import { PipesModule } from '@pipe';
import { ApiModule, Configuration } from '@api';
import { environment } from '../environments/environment';
import { defineCustomElements as designSystemDefineElements } from '@cp-aida/design-system-icons/loader';
import { JwtModule } from '@auth0/angular-jwt';
import { AppInjector } from './app-injector';
import { InterceptorModule } from '@interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatureToggleService } from './permission/feature-toggle.service';
import { TokenService } from '@permission';

export function apiConfigurationFactory(): Configuration {
  const DOMAIN = '';
  return new Configuration({
    basePath: `${DOMAIN}${environment.config.api}`,
  });
}

export function apiModuleForRoot(): ModuleWithProviders<ApiModule> {
  return {
    ngModule: ApiModule,
    providers: [
      {
        provide: Configuration,
        useFactory: apiConfigurationFactory,
        deps: [],
      },
    ],
  };
}

export function initialize(i18Service: I18Service, featureToggleService: FeatureToggleService) {
  return async function () {
    await Promise.all([featureToggleService.loadFeatures(), i18Service.setActiveLanguage('en-EN')]);
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    InterceptorModule.forRoot(),
    AppSpaRoutingModule,
    StateModule.forRoot(),
    ServicesModule.forRoot(),
    apiModuleForRoot(),
    TranslocoRootModule,
    ComponentsModule,
    DesignSystemModule,
    PipesModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return AppInjector.injector.get(TokenService).token;
        },
        headerName: 'x-aida-authorization',
        skipWhenExpired: true,
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initialize,
      deps: [I18Service, FeatureToggleService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppSpaModule {
  constructor(private injector: Injector) {
    AppInjector.injector = injector;
    designSystemDefineElements();
  }
}
