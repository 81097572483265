import { inject, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { TokenService } from './permission/token.service';
import { SpaListComponent } from './components/spa-list/spa-list.component';
import { BookComponent } from './pages/shell/book/book.component';
import { RemoveFlexibleProductsPipe } from '@pipe';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  router: Router = inject(Router),
  tokenService: TokenService = inject(TokenService),
) => {
  if (tokenService.isTokenExpired()) {
    return router.navigateByUrl('login');
  } else {
    return true;
  }
};

export const routes: Routes = [
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule) },
  {
    path: '',
    loadChildren: () => import('./pages/shell/shell.module').then((m) => m.ShellModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [SpaListComponent, BookComponent, RemoveFlexibleProductsPipe],
})
export class AppSpaRoutingModule {}
