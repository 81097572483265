import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppSpaModule } from './app/app-spa.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// force unregister service worker - can be removed with 1.0 release
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      // unregister service worker
      console.log('serviceWorker unregistered');
      registration.unregister();
    }
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppSpaModule)
  .catch((err) => console.error(err));
