// @ts-strict-ignore
import { catchError, Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { NotificationService } from '@services';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && !err?.error?.question) {
          if ((this.router.url === '/' || this.router.url === '/login') && (err.status === 400 || err.status === 401)) {
            return throwError(() => err);
          }
          if (!(err.url.includes('login') && (err.status === 400 || err.status === 401))) {
            this.notificationService.showError(err);
          } else {
            this.router.navigateByUrl('login');
          }
        }
        return throwError(() => err);
      }),
    );
  }
}
