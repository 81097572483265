import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SpinnerInterceptor } from './spinner.interceptor';
import { AuthTokenInterceptor } from './auth-token.interceptor';

@NgModule({ imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class InterceptorModule {
  static forRoot(): ModuleWithProviders<InterceptorModule> {
    return {
      ngModule: InterceptorModule,
      providers: [
        SpinnerInterceptor,
        AuthTokenInterceptor,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SpinnerInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthTokenInterceptor,
          multi: true,
        },
      ],
    };
  }
}
