import { finalize, Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreService } from '@state';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private storeService: StoreService) {}

  setLoading(isLoading: boolean | undefined) {
    this.storeService.set('loadingData', isLoading);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.setLoading(true);
    return next.handle(request).pipe(finalize(() => this.setLoading(false)));
  }
}
